import React from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { legalPageStyle } from '../assets/jss/pages/legalPageStyle';
import { Helmet } from 'react-helmet';
import { CloudDownloadOutlined } from '@material-ui/icons';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';

interface LegalDocumentPageProps extends PageRendererProps {
  data: {
    legalDocument: {
      frontmatter: {
        title: string;
        slug: string;
        date: string;
        datePretty: string;
        downloadUrl: string;
      };
      html: string;
    };
  };
}

export const legalDocQuery = graphql`
  query($legalDocId: String!) {
    legalDocument: markdownRemark(id: { eq: $legalDocId }) {
      frontmatter {
        title
        slug
        date
        datePretty: date(formatString: "DD MMMM, YYYY")
        downloadUrl
      }
      html
    }
  }
`;

const useStyles = makeStyles(legalPageStyle);

const LegalDocumentPageTemplate: React.FC<LegalDocumentPageProps> = ({ data }) => {
  const { legalDocument } = data;

  const classes = useStyles();

  return (
    <div className={classes.legalPage}>
      <Helmet>
        <title>{legalDocument.frontmatter.title}</title>
      </Helmet>

      <div className={classNames(classes.section)}>
        <div className={classes.container}>
          <Breadcrumbs>
            <Typography style={{ color: 'gray' }}>{legalDocument.frontmatter.title}</Typography>
          </Breadcrumbs>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Typography variant="subtitle2" gutterBottom style={{ color: 'gray', textAlign: 'right' }}>
              Laatst bijgewerkt: {legalDocument.frontmatter.datePretty}
            </Typography>

            <br />

            <div style={{ userSelect: 'none' }} dangerouslySetInnerHTML={{ __html: legalDocument.html }} />
            <div style={{ textAlign: 'right', marginTop: '50px' }}>
              <a rel="noreferrer" target="_blank" title="Download" href={legalDocument.frontmatter.downloadUrl}>
                Download <CloudDownloadOutlined style={{ height: '22px', width: '22px', verticalAlign: 'top' }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalDocumentPageTemplate;
