import { section, container, grayColor, cardTitle, breadcrumbs, main, mainRaised, gridItem } from '../materialKitBase';

import { createStyles } from '@material-ui/core/styles';

export const legalPageStyle = createStyles({
  '@global': {
    ol: {
      padding: '0 0 0 25px',
    },
    ul: {
      padding: '0 0 0 25px',
    },
    p: {
      margin: 0,
    },
    'p + p': {
      margin: '14px 0',
    },
    '#artikel-2--identiteit ~ p': {
      userSelect: 'text',
    },
    a: {
      // check more https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      // hyphens: 'auto',
    },
  },
  main,
  mainRaised,
  breadcrumbs,
  gridItem,
  container: {
    ...container,
  },
  section: {
    ...section,
    padding: '70px 0px',
  },
  icon: {
    marginRight: '5px',
    width: 20,
    height: 20,
  },
  link: {
    display: 'flex',
  },
  textCenter: {
    textAlign: 'center',
  },
  legalPage: {
    '& $mainRaised': {
      margin: '0',
      padding: '20px',
    },
    backgroundColor: grayColor[2],
  },
  textMuted: {
    color: grayColor[22],
    fontWeight: 'normal',
  },
});
